import React, { useEffect, useState } from "react";
import { graphql } from "gatsby";
import { GatsbyImage, getImage, getSrc } from "gatsby-plugin-image";
import { isBefore, isAfter } from "date-fns";

import Layout from "../../components/layout/Layout";
import { WebinarVideosAndEventsSection } from "../../components/Event";
import ContactPersonsSection from "../../components/StandaloneCountryContactPersons";

import { pushSAEvent } from "../../helpers";

export default ({ data, location }) => {
    const {
        language,
        eventsPageTitle,
        eventsPageSeoDescription,
        eventsPageHeroTitle,
        eventsPageHeroIngress,
        eventsPageHeroImage,
        eventsPageUpcomingEventsTitle,
        eventsPagePastEventsTitle,
        events,
        eventsPageUpcomingWebinarsTitle,
        webinars,
        contactPersonsTitle,
        contactPersons
    } = data.contentfulFrontPageForIndividualCountries;
    const sortedEvents = events.slice().sort((a, b) => new Date(b.startTime) - new Date(a.startTime));
    const upcomingEvents = sortedEvents.filter((e) => isBefore(new Date(), new Date(e.startTime)));
    const upcomingWebinars = webinars.filter((w) => isBefore(new Date(), new Date(w.webinarStartTime))).sort((a, b) => new Date(b.webinarStartTime) - new Date(a.webinarStartTime));
    const pastEvents = sortedEvents.filter((e) => isAfter(new Date(), new Date(e.startTime)));

    useEffect(() => {
        pushSAEvent(`visit_${language}_standalone_country_events_page`);
    }, []);

    const heroTitleParts = eventsPageHeroTitle.split(' ');

    return (
        <Layout
            location={location}
            title={eventsPageTitle}
            description={eventsPageSeoDescription}
            language={language}
            seoData={{ image: getSrc(eventsPageHeroImage) }}
            transparentNavigation={true}
            pageContentId="standalone-country-page-content"
        >
            <main
                id="standalone-country-page-content"
                style={{ marginTop: "-5rem" }}
            >
                <HeroSection
                    coverImage={eventsPageHeroImage}
                    titlePart1={heroTitleParts[0]}
                    titlePart2={heroTitleParts[1]}
                    description={eventsPageHeroIngress}
                />

                <UpcomingEventsSection
                    title={eventsPageUpcomingEventsTitle}
                    upcomingEvents={upcomingEvents}
                />

                <UpcomingWebinarsSection
                    title={eventsPageUpcomingWebinarsTitle}
                    upcomingWebinars={upcomingWebinars}
                />

                <PastEventsSection
                    title={eventsPagePastEventsTitle}
                    pastEvents={pastEvents}
                />

                <ContactPersonsSection
                    title={contactPersonsTitle}
                    contactPersons={contactPersons}
                />
            </main>
        </Layout>
    );
};

const HeroSection = ({
    coverImage,
    titlePart1,
    titlePart2,
    description
}) => (
    <section className="hero is-fullheight">
        <GatsbyImage
            image={getImage(coverImage)}
            alt=""
            style={{
                width: "100%",
                height: "100%",
                position: "absolute",
                top: "0",
                left: "0"
            }}
        />
        <div className="hero-body">
            <div
                className="container has-text-centered"
                style={{ maxWidth: '800px' }}
            >
                <h1 className="title size-100 is-w margin-bottom-0">
                    <span className="is-white font-variable"
                        style={{ lineHeight: '1' }}
                    >
                        {titlePart1}
                    </span>
                    <br />
                    <span className="is-white font-bold is-transparent text-stroke-white"
                        style={{ lineHeight: '1' }}
                    >
                        {titlePart2}
                    </span>
                </h1>
                <div className="is-flex is-justify-content-center margin-top-40 is-half is-offset-one-quarter">
                    <p className="is-white font-regular size-22 margin-bottom-10"
                        style={{
                            maxWidth: '550px',
                            lineHeight: '1.75'
                        }}
                        dangerouslySetInnerHTML={{ __html: description }}
                    />
                </div>
            </div>
        </div>
    </section>
);

const UpcomingEventsSection = ({
    title,
    upcomingEvents
}) => {
    const [numberOfShownUpcomingEvents, setnumberOfShownUpcomingEvents] = useState(4);

    return (
        <WebinarVideosAndEventsSection
            title={title}
            items={upcomingEvents.slice(0, numberOfShownUpcomingEvents)}
            numberOfTotalItems={upcomingEvents.length}
            onLoadMoreButtonClick={() => setnumberOfShownUpcomingEvents((cur) => cur + 4)}
        />
    );
};

const UpcomingWebinarsSection = ({
    title,
    upcomingWebinars
}) => {
    const [numberOfShownWebinars, setNumberOfShownWebinars] = useState(4);

    return (
        <WebinarVideosAndEventsSection
            title={title}
            items={upcomingWebinars.slice(0, numberOfShownWebinars)}
            numberOfTotalItems={upcomingWebinars.length}
            onLoadMoreButtonClick={() => setNumberOfShownWebinars((cur) => cur + 4)}
        />
    );
};

const PastEventsSection = ({
    title,
    pastEvents
}) => {
    const [numberOfShownPastEvents, setNumberOfShownPastEvents] = useState(4);

    return (
        <WebinarVideosAndEventsSection
            title={title}
            items={pastEvents.slice(0, numberOfShownPastEvents)}
            numberOfTotalItems={pastEvents.length}
            onLoadMoreButtonClick={() => setNumberOfShownPastEvents((cur) => cur + 4)}
        />
    );
};

export const query = graphql`query ($contentful_id: String!) {
    contentfulFrontPageForIndividualCountries(contentful_id: { eq: $contentful_id }) {
        language
        eventsPageTitle
        eventsPageSeoDescription
        eventsPageHeroTitle
        eventsPageHeroIngress
        eventsPageHeroImage {
            gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
        }
        eventsPageUpcomingEventsTitle
        eventsPagePastEventsTitle
        events {
            title
            text
            url
            startTime
            endTime
            location
            speakers {
                name
                title
                portrait {
                    gatsbyImageData(width: 90, height: 90, quality: 100)
                }
            }
            image {
                gatsbyImageData(quality: 100, width: 800)
            }
        }
        eventsPageUpcomingWebinarsTitle
        webinars {
            title
            webinarUrl
            webinarStartTime
            webinarLanguage
            webinarKeywords
            webinarEndTime
            webinarSpeakers {
                name
                title
                portrait {
                    gatsbyImageData(width: 90, height: 90, quality: 100)
                }
            }
            webinarImage {
                gatsbyImageData(quality: 100, width: 800)
            }
        }
        contactPersonsTitle
        contactPersons
    }
}`;